





























































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

import Button from '@/components/v2/Button.vue';
import Logo from '@/components/v2/Logo.vue';


@Component({
  name: 'SidebarHeader',
  components: { Button, Logo },
})
export default class SidebarHeader extends Vue {
  @Getter isDarkTheme!: boolean;

  @Emit()
  toggleMenu() {
    return;
  }

  @Prop({ required: true }) menuOpened!: boolean;

  get showMenu() {
    return process.env.VUE_APP_ORG_NAME !== 'Onclicka';
  }

  get env() {
    return process.env;
  }
}

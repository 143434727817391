








































































import { Component, Prop, Vue } from 'vue-property-decorator';

import Button from '@/components/v2/Button.vue';
import Card from '@/components/v2/Card.vue';

@Component({
  name: 'Alert',
  components: { Button, Card },
})
export default class Alert extends Vue {
  @Prop({ default: 'success' }) type!: 'error' | 'info' | 'success' | 'warning';
  @Prop({ default: false, type: Boolean }) outlined!: boolean;
  @Prop({ default: false, type: Boolean }) dismissible!: boolean;
  @Prop({
    default: ['Clickadilla', 'Onclicka'].includes(process.env.VUE_APP_ORG_NAME),
    type: Boolean,
  })
  rounded!: boolean;

  show = true;
  colors = {
    text: {
      error: 'tw-text-red',
      info: 'tw-text-blue-400',
      success: 'tw-text-mybid-secondary',
      warning: 'tw-text-yellow-500',
    },
    border: {
      error: 'tw-border-red',
      info: 'tw-border-blue-400',
      success: 'tw-border-mybid-secondary',
      warning: 'tw-border-yellow-500',
    },
    background: {
      error: 'tw-bg-red',
      info: 'tw-bg-blue-400',
      success: 'tw-bg-mybid-secondary',
      warning: 'tw-bg-yellow-500',
    },
  };

  changeVisible(show: boolean) {
    this.show = show;
  }

  get env() {
    return process.env;
  }
}

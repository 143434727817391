


















import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'WarningIcon',
})
export default class WarningIcon extends Vue {}

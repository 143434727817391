



























import { Component, Vue } from 'vue-property-decorator';

@Component({
  name: 'CloseIcon',
})
export default class CloseIcon extends Vue {}



























































































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

import Loader from '@/components/v2/Loader.vue';

@Component({
  name: 'Button',
  components: { Loader },
})
export default class Button extends Vue {
  @Emit()
  click() {
    this.clearFocused();
    return;
  }

  @Prop({ default: false, type: Boolean }) icon!: boolean;
  @Prop({ type: String }) href!: string;
  @Prop({ default: 'auto' }) size!: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'auto';
  @Prop({ default: false, type: Boolean }) text!: boolean;
  @Prop({ default: false, type: Boolean }) block!: boolean;
  @Prop({
    default: ['Clickadilla', 'Onclicka'].includes(process.env.VUE_APP_ORG_NAME),
    type: Boolean,
  })
  rounded!: boolean;
  @Prop({ default: false, type: Boolean }) loading!: boolean;
  @Prop({ default: false, type: Boolean }) disabled!: boolean;
  @Prop({ default: ['Clickadilla'].includes(process.env.VUE_APP_ORG_NAME), type: Boolean })
  additionalStyles!: boolean;
  @Prop({ default: false, type: Boolean }) textWrap!: boolean;
  @Prop({default: false, type: Boolean}) customSize!: boolean;
  @Prop({ default: 'none' }) color!:
    | 'primary'
    | 'primary-light'
    | 'secondary'
    | 'secondary-light'
    | 'error'
    | 'research'
    | 'without-color'
    | 'none'
    | 'outlined-light'
    | 'outlined';

  get tag() {
    return this.href ? 'a' : 'button';
  }

  get env() {
    return process.env;
  }

  clearFocused() {
    (this.$refs.customButton as any)?.blur?.();
  }
}
